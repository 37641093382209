import React from "react"
import styled from 'styled-components'
import {
  Buttons, GreenBarSection, HeaderBanner, LightBlueSection,
  Text, WhiteSection, Flex, Vis, ButtonGreen, MOBILE_ONLY_CSS_WRAPPER
} from '../components/common'
import Layout from "../components/layout"
import MobileNumberField from '../components/MobileInput'
import EmailMobileInput from "../components/EmailMobileInput"
import AccessKey from '../components/AccessKey'
import { navTo } from '../utils/navigation'
import InfoIconSvg from '../images/Info_Dark_18.svg'
import api from '../utils/api'
import GlobalContext from '../components/GlobalContext'
import { ENABLE_EMAIL_LOGIN } from "../utils/featureStatus"

const InfoIcon = styled.img`
  height:20px;
  width:20px;
  border-radius:10px;
  margin: auto; 
`
const ErrorBootstrapSection = styled.div`
  margin: 20px 0px;
  ${MOBILE_ONLY_CSS_WRAPPER('margin: 20px 18px;')}
`

const BodyWrapper = styled(Flex)`
`

const Footer = styled(Flex)`
`

const mobileRegexPattern = /^((04\d{8}))$/
const emailRegexPattern = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/

const EnterMobileNumberSection = ({ handleAccessKeyChange, accessKey, mobile, handleChange, isMobileValid, handleKeyDown }) => (<>
  <Text.InputFieldHeader style={{ paddingBottom: '10px' }}>Access key</Text.InputFieldHeader>
  <AccessKey accesskeyValue={accessKey} onChange={handleAccessKeyChange} autoFocus onKeyDown={handleKeyDown} />
  <Text.InputFieldHeader style={{ paddingTop: '30px', paddingBottom: '10px' }}>Mobile number</Text.InputFieldHeader>
  <MobileNumberField mobileValue={mobile} onChange={handleChange} status={isMobileValid === null ? null : isMobileValid === true ? 'success' : 'failed'} onKeyDown={handleKeyDown} />
  <Text.HelperText style={{ paddingTop: '10px', color: `${isMobileValid === null || isMobileValid === true ? '#005564' : '#E84A5E'}` }}>
    {isMobileValid === false ? 'Your mobile number looks not right. Try again' : 'Please enter your mobile number'}</Text.HelperText>
</>)

const EnterEmailOrMobileSection = ({ handleAccessKeyChange, accessKey, emailMobile, handleChange, isEmailMobileValid, handleKeyDown }) => (<>
  <Text.InputFieldHeader style={{ paddingBottom: '10px' }}>Access key</Text.InputFieldHeader>
  <AccessKey accesskeyValue={accessKey} onChange={handleAccessKeyChange} autoFocus onKeyDown={handleKeyDown} />
  <Text.InputFieldHeader style={{ paddingTop: '30px', paddingBottom: '10px' }}>Email address or Mobile number</Text.InputFieldHeader>
  <EmailMobileInput emailMobile={emailMobile} onChange={handleChange} status={isEmailMobileValid === null ? null : isEmailMobileValid === true ? 'success' : 'failed'} onKeyDown={handleKeyDown} />
  <Text.HelperText style={{ paddingTop: '10px', color:'#005564'}}>
    {!emailMobile && 'Please enter your email address or mobile number'}
  </Text.HelperText>
</>)
  
  const Commercial = () => {
    const { featureStatus } = React.useContext(GlobalContext)
    const isEnableEmailLoginFlag = ((featureStatus || []).find(feature => ENABLE_EMAIL_LOGIN === feature.name) || {}).flag;
  
    const [formValid, setFormValid] = React.useState(null)
    const [isEmailMobileValid, setIsEmailMobileValid] = React.useState(null)
    const [emailMobile, setEmailMobile] = React.useState('')
    const [isMobileValid, setIsMobileValid] = React.useState(null)
    const [mobile, setMobile] = React.useState('')
    const [accessKey, setAccessKey] = React.useState('')
    const [isMakingRequest, setIsMakingRequest] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [isLoading, setIsloading] = React.useState(false)

    React.useEffect(() => {
      if (isEnableEmailLoginFlag) {
        if (emailMobile) {
          const valid = emailMobile.match(mobileRegexPattern) || emailRegexPattern.test(emailMobile)
          if (valid) {
            setIsEmailMobileValid(true)
          }
        } else {
          setIsEmailMobileValid(null)
        }
      } else {
        if (mobile && mobile.length === 10) {
          const valid = mobile.match(mobileRegexPattern)
          if (valid) {
            setIsMobileValid(true)
          } else {
            setIsMobileValid(false)
          }
        } else {
          setIsMobileValid(null)
        }
      }
      
    }, [emailMobile, mobile])

    React.useEffect(() => {
      // TODO: might need to validate accessKey input
      if (isEnableEmailLoginFlag) {
        if (emailMobile) {
          if (accessKey.length > 0) {
            setFormValid(true)
          } else {
            setFormValid(false)
          }
        } else {
          setFormValid(null)
        }
      } else {
        if (isMobileValid) {
          if (accessKey.length > 0) {
            setFormValid(true)
          } else {
            setFormValid(false)
          }
        } else {
          setFormValid(null)
        }
      }
    }, [accessKey, emailMobile, isMobileValid])

    if (isEnableEmailLoginFlag) {
      const requestOTPToken = () => {
        setIsloading(true)
        api.sendCommOTP(accessKey, emailMobile).then(() => {
          navTo.letsgetyouverifiedotp_4({ mobile: emailMobile, isCommercial: true })
          setIsloading(false)
        }).catch((err) => {
          const { response } = err
          console.log("response", response)
          setIsloading(false)
          if (response && response.status === 404) {
            setErrorMessage("Invalid login information")
          } else {
            setErrorMessage("Request One-Time Password error, please try again")
          }
        })
      }
    
      const handleChange = (e) => {
        const srcval = e.target.value
        setEmailMobile(srcval)
    
        if(!e.target.value) {
          setErrorMessage("")
        }
      }
    
      const handleAccessKeyChange = (e) => {
        const srcval = e.target.value
        setAccessKey(srcval)
      }
    
      const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          if (formValid) {
            requestOTPToken()
          }
        }
      }
    
      const ErrorMessageSection = () => (<>
        {errorMessage &&
          <ErrorBootstrapSection className="bootstrap" style={{ marginBottom: '0px' }}>
            <div className="alert alert-danger">{errorMessage}</div>
          </ErrorBootstrapSection>
        }
      </>)
    
      const InfoSection = () => (<Flex>
        <div><InfoIcon src={InfoIconSvg} /></div>
        <Text.HelperText style={{ paddingLeft: '15px' }}>Your mobile number will help us to identify you later, if you wish to visit your consent dashboard</Text.HelperText>
      </Flex>)
    
    
      const ButtonGroup = ({ mobile = false }) => {
        return (
          <ButtonGreen
            data-testid="email-feature-next-button"
            style={{ width: `${mobile ? '100%' : '320px'}`, flex: '0 0 auto' }}
            onClick={requestOTPToken}
            disabled={!formValid || isMakingRequest}>
            {!isLoading && <span>Next</span>}
            {isLoading && <span className="bootstrap"><div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div></span>}
          </ButtonGreen>
        )
      }
    
      const LetGetVerify = "myCDRdata pro"
      const subtext = "We’ll use your access key and Email address or Mobile number to verify you via a one-time password"
    
      return (
        <Layout.ColumnLayout fitToHeightOnMobile={true} title={LetGetVerify}>
          <Flex flex="nogrow center" style={{ minHeight: '55px', padding: '19px 24px', background: '#41B6E6' }}>
            <Text.BannerText style={{ textAlign: 'center', color: '#FFFFFF' }}>Not subscribed? Test and monitor data holder endpoints with myCDRdata pro.
              <a rel="noreferrer noopener" style={{ fontWeight: '800', color: '#FFFFFF' }} target="_blank" href='https://www.cuscalpayments.com.au/data/data-holder-testing-services/'> Subscribe now</a></Text.BannerText></Flex>
          <HeaderBanner hamburger={true} />
    
          {/* mobile only */}
          <Vis.MobileOnly flex="col">
            <BodyWrapper flex="col nowgrow">
              <WhiteSection flex="col nogrow" style={{ margin: '29px 10px 0px 10px' }}>
                <Text.H1 style={{ marginTop: '0px', marginBottom: '16px' }}>{LetGetVerify}</Text.H1>
                <GreenBarSection>{subtext}</GreenBarSection>
              </WhiteSection>
              <WhiteSection flex="col nogrow">
                <EnterEmailOrMobileSection handleAccessKeyChange={handleAccessKeyChange} accessKey={accessKey} emailMobile={emailMobile} handleChange={handleChange} isEmailMobileValid={isEmailMobileValid} handleKeyDown={handleKeyDown} />
              </WhiteSection>
    
              <ErrorMessageSection />
            </BodyWrapper>
            <Footer flex="col nogrow">
              <WhiteSection flex="row nogrow" style={{ margin: '0px 0px 0px 0px' }}>
                <ButtonGroup mobile={true} />˜
              </WhiteSection>
            </Footer>
    
          </Vis.MobileOnly>
    
          {/* Tablet only */}
          <Vis.TabletOnly flex="col">
            <WhiteSection flex="col nogrow" style={{ margin: '0px 35px 0px 38px' }}>
              <Text.H1>{LetGetVerify}</Text.H1>
              <GreenBarSection>{subtext}</GreenBarSection>
            </WhiteSection>
            <LightBlueSection flex="col hcenter nogrow" style={{ margin: '0px 70px', padding: '50px 20px' }}>
              <Flex flex="col" style={{ width: '420px' }}>
                <EnterEmailOrMobileSection handleAccessKeyChange={handleAccessKeyChange} accessKey={accessKey} emailMobile={emailMobile} handleChange={handleChange} isEmailMobileValid={isEmailMobileValid} handleKeyDown={handleKeyDown} />
    
                <ErrorMessageSection />
    
                <Flex flex="row nogrow" style={{ marginTop: '25px', padding: '15px 0 80px 0px' }}>
                  {/* <InfoSection /> */}
                </Flex>
              </Flex>
            </LightBlueSection>
            {/* fit to full page height on mobile */}
            <Flex />
            <WhiteSection flex="row hcenter" style={{ margin: '40px 0px' }}>
              <ButtonGroup />
            </WhiteSection>
          </Vis.TabletOnly>
    
          {/* Desktop only */}
          <Vis.DesktopOnly flex="col">
            <WhiteSection flex="row" style={{ margin: '52px 100px 0px 100px' }}>
              <WhiteSection flex="col w50" style={{ padding: '0px' }}>
                <WhiteSection flex="col nogrow">
                  <Text.H1>{LetGetVerify}</Text.H1>
                  <GreenBarSection>{subtext}</GreenBarSection>
                </WhiteSection>
                <WhiteSection flex="row" style={{ marginTop: "82px", padding: "0px 0px", width: '424px' }}>
                  <ButtonGroup />
                </WhiteSection>
              </WhiteSection>
              <Flex flex="w50 col" style={{ paddingTop: '35px' }}>
                <LightBlueSection flex="col hcenter nogrow" style={{ padding: '50px 0 50px 0px', height: '328px', maxHeight: '428px' }}>
                  <Flex flex="col" style={{ width: '375px' }}>
                    <EnterEmailOrMobileSection handleAccessKeyChange={handleAccessKeyChange} accessKey={accessKey} emailMobile={emailMobile} handleChange={handleChange} isEmailMobileValid={isEmailMobileValid} handleKeyDown={handleKeyDown} />
    
                    <ErrorMessageSection />
    
    
                  </Flex>
                </LightBlueSection>
              </Flex>
    
            </WhiteSection>
          </Vis.DesktopOnly>
    
    
        </Layout.ColumnLayout>
      )
    } else {
    
      const requestOTPToken = () => {
        setIsloading(true)
        api.sendCommOTP(accessKey, mobile).then(() => {
          navTo.letsgetyouverifiedotp_4({ mobile, isCommercial: true })
          setIsloading(false)
        }).catch((err) => {
          const { response } = err
          console.log("response", response)
          setIsloading(false)
          if (response && response.status === 404) {
            setErrorMessage("Invalid accessKey and mobile combination")
          } else {
            setErrorMessage("Request One-Time Password error, please try again")
          }
        })
      }
    
      const handleChange = (e) => {
        const srcval = e.target.value
        setMobile(srcval)
      }
    
      const handleAccessKeyChange = (e) => {
        const srcval = e.target.value
        setAccessKey(srcval)
      }
    
      const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          if (formValid) {
            requestOTPToken()
          }
        }
      }
    
      const ErrorMessageSection = () => (<>
        {errorMessage &&
          <ErrorBootstrapSection className="bootstrap" style={{ marginBottom: '0px' }}>
            <div className="alert alert-danger">{errorMessage}</div>
          </ErrorBootstrapSection>
        }
      </>)
    
      const InfoSection = () => (<Flex>
        <div><InfoIcon src={InfoIconSvg} /></div>
        <Text.HelperText style={{ paddingLeft: '15px' }}>Your mobile number will help us to identify you later, if you wish to visit your consent dashboard</Text.HelperText>
      </Flex>)
    
    
      const ButtonGroup = ({ mobile = false }) => {
        return (
          <ButtonGreen
            data-testid="mobile-feature-next-button"
            style={{ width: `${mobile ? '100%' : '320px'}`, flex: '0 0 auto' }}
            onClick={requestOTPToken}
            disabled={!formValid || isMakingRequest}>
            {!isLoading && <span>Next</span>}
            {isLoading && <span className="bootstrap"><div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div></span>}
          </ButtonGreen>
        )
      }
    
      const LetGetVerify = "myCDRdata pro"
      const subtext = "We’ll use your access key and mobile number to verify you via a one-time password"
    
      return (
        <Layout.ColumnLayout fitToHeightOnMobile={true} title={LetGetVerify}>
          <Flex flex="nogrow center" style={{ minHeight: '55px', padding: '19px 24px', background: '#41B6E6' }}>
            <Text.BannerText style={{ textAlign: 'center', color: '#FFFFFF' }}>Not subscribed? Test and monitor data holder endpoints with myCDRdata pro.
              <a rel="noreferrer noopener" style={{ fontWeight: '800', color: '#FFFFFF' }} target="_blank" href='https://www.cuscalpayments.com.au/data/data-holder-testing-services/'> Subscribe now</a></Text.BannerText></Flex>
          <HeaderBanner hamburger={true} />
    
          {/* mobile only */}
          <Vis.MobileOnly flex="col">
            <BodyWrapper flex="col nowgrow">
              <WhiteSection flex="col nogrow" style={{ margin: '29px 10px 0px 10px' }}>
                <Text.H1 style={{ marginTop: '0px', marginBottom: '16px' }}>{LetGetVerify}</Text.H1>
                <GreenBarSection>{subtext}</GreenBarSection>
              </WhiteSection>
              <WhiteSection flex="col nogrow">
                <EnterMobileNumberSection handleAccessKeyChange={handleAccessKeyChange} accessKey={accessKey} mobile={mobile} handleChange={handleChange} isMobileValid={isMobileValid} handleKeyDown={handleKeyDown} />
              </WhiteSection>
    
              <ErrorMessageSection />
            </BodyWrapper>
            <Footer flex="col nogrow">
              <WhiteSection flex="row nogrow" style={{ margin: '0px 0px 0px 0px' }}>
                <ButtonGroup mobile={true} />˜
              </WhiteSection>
            </Footer>
    
          </Vis.MobileOnly>
    
          {/* Tablet only */}
          <Vis.TabletOnly flex="col">
            <WhiteSection flex="col nogrow" style={{ margin: '0px 35px 0px 38px' }}>
              <Text.H1>{LetGetVerify}</Text.H1>
              <GreenBarSection>{subtext}</GreenBarSection>
            </WhiteSection>
            <LightBlueSection flex="col hcenter nogrow" style={{ margin: '0px 70px', padding: '50px 20px' }}>
              <Flex flex="col" style={{ width: '420px' }}>
                <EnterMobileNumberSection handleAccessKeyChange={handleAccessKeyChange} accessKey={accessKey} mobile={mobile} handleChange={handleChange} isMobileValid={isMobileValid} handleKeyDown={handleKeyDown} />
    
                <ErrorMessageSection />
    
                <Flex flex="row nogrow" style={{ marginTop: '25px', padding: '15px 0 80px 0px' }}>
                  {/* <InfoSection /> */}
                </Flex>
              </Flex>
            </LightBlueSection>
            {/* fit to full page height on mobile */}
            <Flex />
            <WhiteSection flex="row hcenter" style={{ margin: '40px 0px' }}>
              <ButtonGroup />
            </WhiteSection>
          </Vis.TabletOnly>
    
          {/* Desktop only */}
          <Vis.DesktopOnly flex="col">
            <WhiteSection flex="row" style={{ margin: '52px 100px 0px 100px' }}>
              <WhiteSection flex="col w50" style={{ padding: '0px' }}>
                <WhiteSection flex="col nogrow">
                  <Text.H1>{LetGetVerify}</Text.H1>
                  <GreenBarSection>{subtext}</GreenBarSection>
                </WhiteSection>
                <WhiteSection flex="row" style={{ marginTop: "82px", padding: "0px 0px", width: '424px' }}>
                  <ButtonGroup />
                </WhiteSection>
              </WhiteSection>
              <Flex flex="w50 col" style={{ paddingTop: '35px' }}>
                <LightBlueSection flex="col hcenter nogrow" style={{ padding: '50px 0 50px 0px', height: '328px', maxHeight: '428px' }}>
                  <Flex flex="col" style={{ width: '375px' }}>
                    <EnterMobileNumberSection handleAccessKeyChange={handleAccessKeyChange} accessKey={accessKey} mobile={mobile} handleChange={handleChange} isMobileValid={isMobileValid} handleKeyDown={handleKeyDown} />
    
                    <ErrorMessageSection />
    
    
                  </Flex>
                </LightBlueSection>
              </Flex>
    
            </WhiteSection>
          </Vis.DesktopOnly>

        </Layout.ColumnLayout>
      )
    }
}

export default Commercial
