import React from 'react'
import styled from 'styled-components'
import RoundCircle from '../images/Tick_inactive_30.svg'
import CheckCircle from '../images/Tick_active_30.svg'
import ErrorCircle from '../images/Circle_Error_30.svg'

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;

  background: #FFFFFF;
  border: 1px solid #373737;
  /* Form Box Shadow */
  box-shadow: 0px 2px 8px rgba(79, 119, 169, 0.12);
  border-radius: 9px;
`
const InputText = styled.input`
  
  text-align: left;
  width: 100%;
  border-style: none;
  &:focus {
    outline: none;
    letter-spacing: 4px;
  }
  font-family: Arial;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 4px;
  // padding-top: 8px;
  :focus::-webkit-input-placeholder {
    opacity: 1;
    letter-spacing: 4px;
    font-size: 24px;
    line-height: 24px;
  }
  ::-webkit-input-placeholder {
    // position:relative;
    // top:3px;
    letter-spacing: 4px;
    font-size: 24px;
    line-height: 24px;
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageContainer = styled.img`
  margin: auto; 
`
const EmailMobileInput = ({ status = null, inputValue, onChange,  ...props }) => {
  return (
    <InputContainer status={status}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div style={{ width: "180px" }}>
          <InputText data-testid="email-mobile-input" aria-label="Email address or mobile number input field" type="text" value={inputValue} onChange={onChange} {...props} />
        </div>
      </div>
      <IconContainer><ImageContainer src={status === null ? RoundCircle : status === 'success' ? CheckCircle : ErrorCircle} width="30" height="30" /></IconContainer>
    </InputContainer>
  )
}

export default EmailMobileInput